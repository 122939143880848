import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Confirm from './Confirm';
import Logo from './Logo';
import Help from './Help';

const Overlay = ({
  children, open, className, onClick,
}) => (
  <div className={classNames('overlay', className, { open })} onClick={onClick}>
    {children}
  </div>
);

Overlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Overlay.defaultProps = {
  className: '',
  onClick: undefined,
  open: false,
};

export { Confirm, Logo, Help };

export default Overlay;
