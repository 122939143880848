import React from 'react';
import Overlay from 'components/Overlay';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Confirm = ({
  open, message, description, onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Overlay className="confirm" open={open}>
      <div className="confirm__message">{message}</div>
      {!!description && <div className="confirm__description">{description}</div>}
      <div className="confirm__buttons">
        <button type="button" className="button" onClick={() => onConfirm(false)}>
          {t('confirm.no')}
        </button>
        <button type="button" className="button" onClick={() => onConfirm(true)}>
          {t('confirm.yes')}
        </button>
      </div>
    </Overlay>
  );
};

Confirm.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  open: false,
  description: '',
};

export default Confirm;
