import { createTypes } from 'store/utils';

export const KEY = 'survey';

export const ANSWERS = 'answers';
export const SET_ANSWER = 'SET_ANSWER';
export const SET_ANSWERS = 'SET_ANSWERS';

export const STEP = 'step';
export const SET_STEP = 'SET_STEP';

export const COMPLETE = 'complete';
export const SET_COMPLETE = 'SET_COMPLETE';

export const QUESTIONS = 'questions';
export const SET_QUESTIONS = 'SET_QUESTIONS';

export const MyTypesArray = [SET_ANSWER, SET_STEP, SET_COMPLETE, SET_QUESTIONS, SET_ANSWERS];
export const TYPES = createTypes(KEY, MyTypesArray);
