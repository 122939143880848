import { createTypes } from 'store/utils';

export const KEY = 'admin';

export const TOKEN = 'token';
export const SET_TOKEN = 'SET_TOKEN';

export const ERROR = 'error';
export const SET_ERROR = 'SET_ERROR';

export const SETTINGS = 'settings';
export const SET_SETTINGS = 'SET_SETTINGS';

export const MyTypesArray = [SET_TOKEN, SET_ERROR, SET_SETTINGS];
export const TYPES = createTypes(KEY, MyTypesArray);
