import React, {
  useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import BackgroundImg from './BackgroundImg';

const Background = ({
  src, onSizeChange,
}) => {
  const [style, setStyle] = useState();
  const [imgRatio, setImgRatio] = useState();
  const { width, height, ref } = useResizeDetector();

  useEffect(
    () => {
      if (src) {
        const loadimg = new Image();
        loadimg.src = src;
        loadimg.onload = () => {
          setImgRatio(loadimg.width / loadimg.height);
        };
      }
    },
    [src],
  );

  const contentRatio = useMemo(() => width / height, [height, width]);

  useEffect(() => {
    if (width && height && imgRatio && contentRatio) {
      if (contentRatio > imgRatio) {
        setStyle({ width: 'auto', height: '100%' });
      } else {
        setStyle({ width: '100%', height: 'auto' });
      }
    }
  }, [contentRatio, height, imgRatio, width]);

  return (
    <div className="background" ref={ref}>
      <BackgroundImg src={src} onSizeChange={onSizeChange} style={style} />
    </div>
  );
};

Background.propTypes = {
  src: PropTypes.string,
  onSizeChange: PropTypes.func.isRequired,
};

Background.defaultProps = {
  src: null,
};

export default Background;
