import { TYPES, SET_BACKGROUNDS, BACKGROUNDS, SET_VISIBILITY, REMOVE_BACKGROUND } from './constants';
import { createReducer, assignPayloadToKey } from 'store/utils';

const defaultState = {};

const behaviors = {
	[TYPES[SET_BACKGROUNDS]]: assignPayloadToKey(BACKGROUNDS),
	[TYPES[SET_VISIBILITY]]: (state, { payload }) => ({
		...state,
		[BACKGROUNDS]: state[BACKGROUNDS].map(
			background => (background.id === payload ? { ...background, invisible: !background.invisible } : background)
		),
	}),
	[TYPES[REMOVE_BACKGROUND]]: (state, { payload }) => ({
		...state,
		[BACKGROUNDS]: state[BACKGROUNDS].filter(({ id }) => id !== payload),
	}),
};

export default createReducer(behaviors, defaultState);
