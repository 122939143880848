import { createTypes } from 'store/utils';

export const KEY = 'images';

export const IMAGES = 'images';
export const SET_IMAGES = 'SET_IMAGES';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const IMAGES_OPEN = 'imagesOpen';
export const SET_IMAGES_OPEN = 'SET_IMAGES_OPEN';

export const MyTypesArray = [SET_IMAGES, SET_IMAGES_OPEN, REMOVE_IMAGE];
export const TYPES = createTypes(KEY, MyTypesArray);
