import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  ANSWERS,
  SET_ANSWER,
  SET_STEP,
  STEP,
  COMPLETE,
  SET_COMPLETE,
  QUESTIONS,
  SET_QUESTIONS,
  SET_ANSWERS,
} from './constants';

const defaultState = { [ANSWERS]: {}, [STEP]: -1 };

const behaviors = {
  [TYPES[SET_STEP]]: assignPayloadToKey(STEP),
  [TYPES[SET_COMPLETE]]: assignPayloadToKey(COMPLETE),
  [TYPES[SET_QUESTIONS]]: assignPayloadToKey(QUESTIONS),
  [TYPES[SET_ANSWERS]]: assignPayloadToKey(ANSWERS),
  [TYPES[SET_ANSWER]]: (state, { payload: { questionId, answer } }) => ({
    ...state,
    [ANSWERS]: {
      ...state[ANSWERS],
      [questionId]: answer,
    },
  }),
};

export default createReducer(behaviors, defaultState);
