import { createAction } from 'store/utils';

import store from 'store';
import {
  setLoading, setUser,
  setRole,
} from 'App/redux/actions';

import roles from 'App/roles';
import {
  setColor,
} from 'components/Drawer/redux/actions';
import {
  TYPES, SET_CONNECTED, SET_QUEUE, SET_NETWORK_ERROR,
} from './constants';

export const setConnected = createAction(TYPES[SET_CONNECTED]);
export const setQueue = createAction(TYPES[SET_QUEUE]);
export const setNetworkError = createAction(TYPES[SET_NETWORK_ERROR]);

export const fetchUser = async (handler) => {
  store.dispatch(setLoading(true));

  await handler.fetch('user', null, (info) => {
    const {
      user, color,
    } = info;

    store.dispatch(setUser(user));
    store.dispatch(setRole(roles.STUDENT));
    store.dispatch(setColor(color));
  });

  store.dispatch(setLoading(false));
};

export const setError = (status) => {
  store.dispatch(setLoading(false));
  switch (status) {
    // Ignored errors
    case 404:
      break;
    default:
      store.dispatch(setNetworkError(status));
  }
};
