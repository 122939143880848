import React, {
  useMemo,
} from 'react';

import 'rc-slider/assets/index.css';
import './heatmap.scss';

const HeatmapRange = ({ range }) => {
  const colors = useMemo(() => {
    const {
      min, max, gradient,
    } = range;
    const result = [];

    for (let i = min; i < max; i += 4) {
      result.push(`rgb(${gradient[i]},${gradient[i + 1]},${gradient[i + 2]})`);
    }

    const unique = [...new Set(result)];
    return unique.reverse();
  }, [range]);

  return (
    <div className="range">
      {colors.map((color) => (
        <div key={color} className="block" style={{ background: color }} />
      ))}
    </div>
  );
};

export default HeatmapRange;
