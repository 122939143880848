import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  LOADING,
  SET_LOADING,
  USER,
  SET_USER,
  GROUP_ID,
  SET_GROUP_ID,
  ROLE,
  SET_ROLE,
  SET_LANG,
  LANG,
  SET_LANGS_OPEN,
  LANGS_OPEN,
  SET_GROUP_CLOSED,
  GROUP_CLOSED,
  SHARED_DRAWINGS,
  SET_SHARED_DRAWINGS,
  RESTITUTION_OPEN,
  SET_RESTITUTION_OPEN,
  DISPLAY_SURVEY,
  SET_DISPLAY_SURVEY,
  SET_DISPLAY_TUTORIAL,
  DISPLAY_TUTORIAL,
  ENDING_SURVEY,
  SET_ENDING_SURVEY, DISPLAY_DRAWING_COMMENT, SET_DISPLAY_DRAWING_COMMENT,
  SET_TUTORIAL_STARTED,
  TUTORIAL_STARTED,
  SET_RESTITUTION_SHARED_ITEMS,
  RESTITUTION_SHARED_ITEMS,
  SET_SURVEY_STARTED,
  SURVEY_STARTED,
  SET_SURVEY_COMPLETE,
  SURVEY_COMPLETE,
  SET_GROUP_ENDING,
  GROUP_ENDING,
} from './constants';
import roles from '../roles';

const defaultState = {
  [LANG]: null,
  [USER]: {},
  [ROLE]: roles.NONE,
  [LANGS_OPEN]: false,
  [SHARED_DRAWINGS]: [],
};

const behaviors = {
  [TYPES[SET_LOADING]]: assignPayloadToKey(LOADING),
  [TYPES[SET_USER]]: assignPayloadToKey(USER),
  [TYPES[SET_GROUP_ID]]: assignPayloadToKey(GROUP_ID),
  [TYPES[SET_ROLE]]: assignPayloadToKey(ROLE),
  [TYPES[SET_LANG]]: assignPayloadToKey(LANG),
  [TYPES[SET_LANGS_OPEN]]: assignPayloadToKey(LANGS_OPEN),
  [TYPES[SET_GROUP_CLOSED]]: assignPayloadToKey(GROUP_CLOSED),
  [TYPES[SET_SHARED_DRAWINGS]]: assignPayloadToKey(SHARED_DRAWINGS),
  [TYPES[SET_RESTITUTION_OPEN]]: assignPayloadToKey(RESTITUTION_OPEN),
  [TYPES[SET_DISPLAY_SURVEY]]: assignPayloadToKey(DISPLAY_SURVEY),
  [TYPES[SET_ENDING_SURVEY]]: assignPayloadToKey(ENDING_SURVEY),
  [TYPES[SET_DISPLAY_TUTORIAL]]: assignPayloadToKey(DISPLAY_TUTORIAL),
  [TYPES[SET_DISPLAY_DRAWING_COMMENT]]: assignPayloadToKey(DISPLAY_DRAWING_COMMENT),
  [TYPES[SET_TUTORIAL_STARTED]]: assignPayloadToKey(TUTORIAL_STARTED),
  [TYPES[SET_SURVEY_STARTED]]: assignPayloadToKey(SURVEY_STARTED),
  [TYPES[SET_RESTITUTION_SHARED_ITEMS]]: assignPayloadToKey(RESTITUTION_SHARED_ITEMS),
  [TYPES[SET_GROUP_ENDING]]: assignPayloadToKey(GROUP_ENDING),

  [TYPES[SET_SURVEY_COMPLETE]]: (state, { payload: surveyComplete }) => ({
    ...state,
    [USER]: {
      ...state[USER],
      [SURVEY_COMPLETE]: surveyComplete,
    },
  }),
};

export default createReducer(behaviors, defaultState);
