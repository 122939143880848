import { createAction } from 'store/utils';
import ajaxHandler from 'network/ajaxHandler';
import store from 'store';

import {
  CHANGE_VISIBILITY,
  CAN_REMOVE,
  CHANGE_MODE,
  MAX_DRAWINGS,
  LINE_DETECTION,
  UI_DISABLED,
  ANONYMOUS,
} from 'components/Drawer/redux/constants';
import {
  setChangeVisibility,
  setCanRemove,
  setChangeMode,
  setMaxDrawings,
  setLineDetection,
  setUIDisabled,
  setAnonymous,
} from 'components/Drawer/redux/actions';
import {
  TYPES,
  SET_ROLE,
  SET_USER,
  SET_LOADING,
  SET_LANG,
  SET_LANGS_OPEN,
  LANG,
  SET_GROUP_CLOSED,
  SET_SHARED_DRAWINGS,
  SET_RESTITUTION_OPEN,
  ENDING_SURVEY,
  SET_DISPLAY_SURVEY,
  DISPLAY_SURVEY,
  SET_ENDING_SURVEY,
  DISPLAY_TUTORIAL,
  SET_DISPLAY_TUTORIAL,
  DISPLAY_DRAWING_COMMENT, SET_DISPLAY_DRAWING_COMMENT,
  SET_TUTORIAL_STARTED,
  SET_RESTITUTION_SHARED_ITEMS,
  SET_SURVEY_STARTED,
  SET_SURVEY_COMPLETE,
  SET_GROUP_ENDING,
} from './constants';
import { getUser } from './selectors';

export const setUser = createAction(TYPES[SET_USER]);
export const setLoading = createAction(TYPES[SET_LOADING]);
export const setRole = createAction(TYPES[SET_ROLE]);
export const setLang = createAction(TYPES[SET_LANG]);
export const setLangsOpen = createAction(TYPES[SET_LANGS_OPEN]);
export const setGroupClosed = createAction(TYPES[SET_GROUP_CLOSED]);
export const setGroupEnding = createAction(TYPES[SET_GROUP_ENDING]);
export const doSetSharedDrawings = createAction(TYPES[SET_SHARED_DRAWINGS]);
export const setRestitutionOpen = createAction(TYPES[SET_RESTITUTION_OPEN]);

export const setDisplaySurvey = createAction(TYPES[SET_DISPLAY_SURVEY]);
export const setEndingSurvey = createAction(TYPES[SET_ENDING_SURVEY]);
export const setDisplayTutorial = createAction(TYPES[SET_DISPLAY_TUTORIAL]);
export const setDisplayDrawingComment = createAction(TYPES[SET_DISPLAY_DRAWING_COMMENT]);

export const setTutorialStarted = createAction(TYPES[SET_TUTORIAL_STARTED]);
export const setSurveyStarted = createAction(TYPES[SET_SURVEY_STARTED]);
export const setRestitutionSharedItems = createAction(TYPES[SET_RESTITUTION_SHARED_ITEMS]);

export const setSurveyComplete = createAction(TYPES[SET_SURVEY_COMPLETE]);

const actions = {
  [CHANGE_VISIBILITY]: setChangeVisibility,
  [CAN_REMOVE]: setCanRemove,
  [CHANGE_MODE]: setChangeMode,
  [MAX_DRAWINGS]: setMaxDrawings,
  [LINE_DETECTION]: setLineDetection,
  [UI_DISABLED]: setUIDisabled,
  [ANONYMOUS]: setAnonymous,
  [LANG]: setLang,
  [DISPLAY_SURVEY]: setDisplaySurvey,
  [ENDING_SURVEY]: setEndingSurvey,
  [DISPLAY_TUTORIAL]: setDisplayTutorial,
  [DISPLAY_DRAWING_COMMENT]: setDisplayDrawingComment,
};

// Trigger setting change (prof only)
export const setSharedDrawings = (drawings) => async (dispatch) => {
  const userId = getUser(store.getState()).id;
  dispatch(doSetSharedDrawings(drawings.filter(({ studentId }) => studentId !== userId)));
};

export const changeSetting = (name, value, otherAction) => async (dispatch) => {
  ajaxHandler.send('group/setting', { name, value });
  dispatch((otherAction || actions[name])(value));
};

// Init settings (students only)
export const setSettings = (settings) => async (dispatch) => {
  Object.keys(settings).forEach((key) => {
    if (actions[key] && settings[key]) {
      dispatch(actions[key](settings[key]));
    }
  });
};

// Update setting (students only)
export const updateSetting = (name, value) => {
  store.dispatch(actions[name](value));
};

export const endSession = () => async (dispatch) => {
  dispatch(setGroupClosed(true));
};
