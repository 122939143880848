import { createTypes } from 'store/utils';

export const KEY = 'adminBackgrounds';

export const BACKGROUNDS = 'backgrounds';
export const SET_BACKGROUNDS = 'SET_BACKGROUNDS';
export const SET_VISIBILITY = 'SET_VISIBILITY';
export const REMOVE_BACKGROUND = 'REMOVE_BACKGROUND';

export const MyTypesArray = [SET_BACKGROUNDS, SET_VISIBILITY, REMOVE_BACKGROUND];
export const TYPES = createTypes(KEY, MyTypesArray);
