import loadable from 'HOC/loadable';
import roles from 'App/roles';
import secure from 'HOC/secure';
// eslint-disable-next-line
import { createBrowserHistory } from 'history';

const Restitution = loadable(() => import('modules/Restitution'));
const Error404 = loadable(() => import('modules/errors/Error404'));

const Admin = secure(loadable(() => import('modules/Admin')))(roles.ADMIN, true, false, '/login');
const Login = loadable(() => import('modules/Admin/Login'));

const End = loadable(() => import('modules/End'));

const UserJoin = loadable(() => import('modules/user/Join'));
const Shell = loadable(() => import('components/Layout/Shell'));

const Error = loadable(() => import('modules/errors/Error'));

export const history = createBrowserHistory();

export const mainRoutes = [
  {
    path: '/user/join',
    exact: true,
    name: 'UserJoin',
    component: UserJoin,
  },
  {
    path: '/admin',
    exact: true,
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
  },
  {
    path: '/error',
    exact: true,
    name: 'Error',
    component: Error,
  },
  {
    path: '/',
    name: 'Shell',
    component: Shell,
  },
  { name: '404', component: Error404 },
];

export default [
  {
    path: '/end/:groupId',
    exact: true,
    name: 'End',
    component: End,
  },
  {
    path: '/restitution',
    exact: true,
    name: 'Restitution',
    component: Restitution,
  },
];
