import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const FormGroup = ({ label, children, className }) => (
	<div className={classNames('form-group', className)}>
		<label className="label">{label}</label>
		<div className="value">{children}</div>
	</div>
);

FormGroup.propTypes = {
	label: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
};

FormGroup.defaultProps = {
	className: '',
};

export default FormGroup;
