import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getLang } from 'App/redux/selectors';
import { setLang } from 'App/redux/actions';

const detectLang = () => {
  try {
    return (
      (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator.userLanguage
    ).split('-')[0];
  } catch (e) {
    return undefined;
  }
};

const LocaleWatch = ({ lang, onSetLang }) => {
  useEffect(() => {
    i18n.changeLanguage(lang);
    if (!lang) {
      const detectedLang = detectLang();
      detectedLang && onSetLang(detectedLang);
    }
  }, [lang, onSetLang]);

  return <div />;
};

LocaleWatch.propTypes = {
  lang: PropTypes.string.isRequired,
  onSetLang: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lang: getLang(state),
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators(
  {
    onSetLang: setLang,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LocaleWatch);
