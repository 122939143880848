import { modes } from 'components/Heatmap';

export const MODE_OPTIONS = [
  { label: 'restitution.modes.dots', value: modes.DOTS },
  {
    label: 'restitution.modes.lines',
    value: modes.LINES,
  },
  {
    label: 'restitution.modes.shapes',
    value: modes.SHAPES,
  },
];

export const COMPARE = 'compare';
export const SUPERPOSE = 'superpose';
export const INLINE = 'inline';

export const RENDERING_OPTIONS = [
  {
    label: 'restitution.rendering.compare',
    value: COMPARE,
  },
  {
    label: 'restitution.rendering.superpose',
    value: SUPERPOSE,
  },
  {
    label: 'restitution.rendering.inline',
    value: INLINE,
  },
];

export const MODE = 'mode';
export const LINE_WIDTH = 'lineWidth';
export const BLUR = 'blur';
export const VALUE = 'value';

export const DEFAULT_HEATMAP_SETTINGS = {
  [MODE]: MODE_OPTIONS[1],
  [LINE_WIDTH]: 10,
  [BLUR]: 0,
  [VALUE]: 30,
};
