export const types = {
  LINE: 'line',
  SHAPE: 'shape',
  DOT: 'dot',
};

export const modes = {
  DRAWING: 0,
  MASK: 1,
  INVERTED_MASK: 2,
};
