import React from 'react';
import Overlay from 'components/Overlay';
import PropTypes from 'prop-types';

import './loading.scss';

const Loading = ({ open }) => (
	<Overlay open={open}>
		<div className="loading-loader">
			<div className="e-loadholder">
				<div className="m-loader" />
			</div>
		</div>
	</Overlay>
);

Loading.propTypes = {
	open: PropTypes.bool,
};

Loading.defaultProps = {
	open: false,
};

export default Loading;
