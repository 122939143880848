import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  SET_SESSIONS_LIST,
  SESSIONS_LIST,
  SET_IMAGES_LIST,
  IMAGES_LIST,
  SET_SESSIONS,
  SET_IMAGE,
  SET_STUDENTS,
  SESSIONS,
  IMAGE,
  STUDENTS,
  SET_RESTITUTION_DATA,
  RESTITUTION_DATA,
  SET_DRAWINGS_NUMBER,
  DRAWINGS_NUMBER,
  SET_RENDERING,
  RENDERING,
  SET_HEATMAP_SETTINGS,
  HEATMAP_SETTINGS,
} from './constants';
import { DEFAULT_HEATMAP_SETTINGS, RENDERING_OPTIONS } from '../constants';

export const defaultSettings = {
  [RENDERING]: RENDERING_OPTIONS[1].value, // Superpose
  [DRAWINGS_NUMBER]: 20,
};

const defaultState = {
  ...defaultSettings,
  [HEATMAP_SETTINGS]: DEFAULT_HEATMAP_SETTINGS,
};

const behaviors = {
  [TYPES[SET_SESSIONS_LIST]]: assignPayloadToKey(SESSIONS_LIST),
  [TYPES[SET_IMAGES_LIST]]: assignPayloadToKey(IMAGES_LIST),

  [TYPES[SET_SESSIONS]]: assignPayloadToKey(SESSIONS),
  [TYPES[SET_IMAGE]]: assignPayloadToKey(IMAGE),
  [TYPES[SET_STUDENTS]]: assignPayloadToKey(STUDENTS),
  [TYPES[SET_DRAWINGS_NUMBER]]: assignPayloadToKey(DRAWINGS_NUMBER),
  [TYPES[SET_RENDERING]]: assignPayloadToKey(RENDERING),
  [TYPES[SET_HEATMAP_SETTINGS]]: assignPayloadToKey(HEATMAP_SETTINGS),

  [TYPES[SET_RESTITUTION_DATA]]: assignPayloadToKey(RESTITUTION_DATA),
};

export default createReducer(behaviors, defaultState);
