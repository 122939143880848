import React, {
  useRef, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Slate from 'components/Slate';
import logger from 'js-logger';
import HeatmapHandler from './heatmap';
import HeatmapDrawingSelector from './HeatmapDrawingSelector';
import HeatmapRange from './HeatmapRange';

import 'rc-slider/assets/index.css';
import './heatmap.scss';
import HeatmapSelectedDrawing from './HeatmapSelectedDrawing';

export const modes = {
  LINES: 'lines',
  SHAPES: 'shapes',
  DOTS: 'dots',
};

const Heatmap = ({
  id, src, drawings, mode, blur, lineWidth, value,
  clickable, onDrawingSelect, selectedDrawing,
}) => {
  const canvas = useRef();
  const heatmap = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [range, setRange] = useState();

  useEffect(() => {
    heatmap.current = new HeatmapHandler(canvas.current);
  }, []);

  useEffect(() => {
    const { width, height } = size;
    canvas.current.width = width;
    canvas.current.height = height;
  }, [size]);

  const processedDrawings = useMemo(() => {
    const { width, height } = size;
    if (width && height) {
      return drawings.map((drawing) => ({
        ...drawing,
        dots: drawing.dots.map(({ x, y }) => ({
          x: (x * width) / drawing.width,
          y: (y * height) / drawing.height,
        })),
      }));
    }
    return undefined;
  }, [drawings, size]);

  useEffect(() => {
    if (processedDrawings) {
      const fn = async () => {
        try {
          setRange(
            await heatmap.current
              .resize()
              .clear()
              .data(processedDrawings)
              .draw(mode.value, value, lineWidth, blur),
          );
        } catch (e) {
          logger.warn('drawing aborted', e);
        }
      };
      fn();
    }
  }, [blur, lineWidth, mode.value, processedDrawings, value]);

  const handleDrawingSelect = (drawing) => {
    if (drawing) {
      const originalDrawing = drawings.find((d) => d.id === drawing.id);
      onDrawingSelect(originalDrawing);
    } else {
      onDrawingSelect();
    }
  };

  return (
    <Slate src={src} onSizeChange={setSize}>
      <div className="drawings heatmap">
        {clickable && (
        <HeatmapDrawingSelector
          size={size}
          drawings={processedDrawings}
          onDrawingSelect={handleDrawingSelect}
          selectedDrawing={selectedDrawing}
        />
        )}
        {selectedDrawing && <HeatmapSelectedDrawing drawing={selectedDrawing} size={size} />}
        <canvas ref={canvas} id={id} />
        {range && <HeatmapRange range={range} />}
      </div>
    </Slate>
  );
};

Heatmap.propTypes = {
  src: PropTypes.string.isRequired,
  drawings: PropTypes.arrayOf(PropTypes.object).isRequired,
  mode: PropTypes.string.isRequired,
  blur: PropTypes.number.isRequired,
  lineWidth: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

Heatmap.defaultProps = {
  className: '',
};

export default Heatmap;
