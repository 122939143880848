import { createTypes } from 'store/utils';

export const KEY = 'adminTimeline';

export const GROUPS_LIST = 'groupsList';
export const SET_GROUPS_LIST = 'SET_GROUPS_LIST';

export const BACKGROUNDS = 'backgrounds';
export const SET_BACKGROUNDS = 'SET_BACKGROUNDS';

export const BACKGROUND = 'background';
export const SET_BACKGROUND = 'SET_BACKGROUND';

export const GROUP = 'group';
export const SET_GROUP = 'SET_GROUP';

export const DATA = 'data';
export const SET_DATA = 'SET_DATA';

export const MyTypesArray = [SET_BACKGROUNDS, SET_GROUPS_LIST, SET_GROUP, SET_BACKGROUND, SET_DATA];
export const TYPES = createTypes(KEY, MyTypesArray);
