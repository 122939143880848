import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES, IMAGES, SET_IMAGES, SET_IMAGES_OPEN, REMOVE_IMAGE, IMAGES_OPEN,
} from './constants';

const defaultState = { [IMAGES]: [] };

const behaviors = {
  [TYPES[SET_IMAGES]]: assignPayloadToKey(IMAGES),
  [TYPES[SET_IMAGES_OPEN]]: assignPayloadToKey(IMAGES_OPEN),
  [TYPES[REMOVE_IMAGE]]: (state, { payload }) => ({
    ...state,
    [IMAGES]: state[IMAGES].filter(({ id }) => id !== payload),
  }),
};

export default createReducer(behaviors, defaultState);
