import { createTypes } from 'store/utils';

export const KEY = 'socket';

export const CONNECTED = 'connected';
export const SET_CONNECTED = 'SET_CONNECTED';

export const QUEUE = 'queue';
export const SET_QUEUE = 'SET_QUEUE';

export const NETWORK_ERROR = 'networkError';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';

export const MyTypesArray = [SET_CONNECTED, SET_QUEUE, SET_NETWORK_ERROR];
export const TYPES = createTypes(KEY, MyTypesArray);
