import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES, CONNECTED, SET_CONNECTED, QUEUE, SET_QUEUE, SET_NETWORK_ERROR, NETWORK_ERROR,
} from './constants';

const defaultState = { [CONNECTED]: false, [QUEUE]: [] };

const behaviors = {
  [TYPES[SET_CONNECTED]]: assignPayloadToKey(CONNECTED),
  [TYPES[SET_QUEUE]]: assignPayloadToKey(QUEUE),
  [TYPES[SET_NETWORK_ERROR]]: assignPayloadToKey(NETWORK_ERROR),
};

export default createReducer(behaviors, defaultState);
