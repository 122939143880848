import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from './Button';
import FormGroup from './FormGroup';

import './menu.scss';

const Menu = ({
  children, className, hidable, open,
}) => (
  <div className={classNames('menu', { hidable, open })}>
    <div className="top">{children}</div>
  </div>
);

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  hidable: PropTypes.bool,
  open: PropTypes.bool,
};

Menu.defaultProps = {
  className: '',
  hidable: false,
  open: false,
};

export default Menu;

export { Button, FormGroup };
