import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import secure from 'HOC/secure';
import roles from 'App/roles';

const button = (role, test) => secure(({
  icon, className, onClick, disabled, active, children,
}) => (
  <button
    className={classNames('button round', className, { disabled, active })}
    onClick={onClick}
  >
    {children || <i className={icon.startsWith('icon-') ? icon : `la la-${icon}`} />}
  </button>
))(role, false, test);

const Button = ({
  role, test, icon, className, onClick, disabled, active, children,
}) => {
  const Instance = button(role, test);
  const props = {
    icon,
    className,
    onClick,
    disabled,
    active,
    children,
  };
  return <Instance {...props} />;
};

Button.propTypes = {
  role: PropTypes.number,
  test: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Button.defaultProps = {
  icon: '',
  role: roles.NONE,
  children: null,
  active: false,
  disabled: false,
  className: '',
  test: undefined,
};

export default Button;
