import React from 'react';
import { connect } from 'react-redux';
import { getRole } from 'App/redux/selectors';
import { Redirect } from 'react-router-dom';

const mapStateToProps = (state) => ({
  userRole: getRole(state),
});

export default (Component) => (requiredRole, redirect, test, route = '/') => connect(mapStateToProps)((props) => {
  const condition = test ? test(props.userRole) : props.userRole >= requiredRole;
  return condition ? <Component {...props} /> : redirect ? <Redirect to={route} /> : null;
});
