import moment from 'moment';
import tokenHandler from './tokenHandler';

const { API_ROOT_PATH } = window.CONFIG;

export const getName = (name, date) => `${name} - ${moment(parseInt(date, 10)).format('DD/MM/YY hh:mm')}`;

export const distance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.sqrt(a * a + b * b);
};

export const getImgUrl = (image) => {
  const token = tokenHandler.getToken();
  return `${API_ROOT_PATH}images/${image.id}?token=${token}`;
};

export const getBackgroundUrl = (background) => {
  const token = tokenHandler.getToken();
  return `${API_ROOT_PATH}backgrounds/${background.id}?token=${token}`;
};

export const getQueryParams = (search) => {
  const query = search.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};
