import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  STUDENTS,
  SET_STUDENTS,
  ADD_DRAWING,
  REMOVE_DRAWING,
  DRAWINGS,
  SET_GROUP_INFO,
  GROUP_INFO,
  SET_STUDENTS_LANG,
  STUDENTS_LANG,
  FLAG_DRAWING,
  SHARE_DRAWING,
  RESET_DRAWINGS,
  SET_SHOW_BACKGROUND,
  SHOW_BACKGROUND,
  SET_SHARING_ACTIVE,
  SHARING_ACTIVE,
} from './constants';

const defaultState = { [STUDENTS]: {}, [SHOW_BACKGROUND]: true };

const behaviors = {
  [TYPES[SET_STUDENTS]]: assignPayloadToKey(STUDENTS),
  [TYPES[SET_STUDENTS_LANG]]: assignPayloadToKey(STUDENTS_LANG),
  [TYPES[SET_GROUP_INFO]]: assignPayloadToKey(GROUP_INFO),
  [TYPES[SET_SHOW_BACKGROUND]]: assignPayloadToKey(SHOW_BACKGROUND),
  [TYPES[SET_SHARING_ACTIVE]]: assignPayloadToKey(SHARING_ACTIVE),
  [TYPES[ADD_DRAWING]]: (state, { payload: { userId, drawing } }) => {
    const { backgroundId } = drawing;
    let drawings = (state[STUDENTS][userId][DRAWINGS] || {})[backgroundId];
    if (!drawings) {
      drawings = [];
    }
    return {
      ...state,
      [STUDENTS]: {
        ...state[STUDENTS],
        [userId]: {
          ...state[STUDENTS][userId],
          [DRAWINGS]: {
            ...(state[STUDENTS][userId][DRAWINGS] || {}),
            [backgroundId]: [...drawings, drawing],
          },
        },
      },
    };
  },
  [TYPES[REMOVE_DRAWING]]: (state, { payload: { userId, drawingId, backgroundId } }) => {
    const drawings = state[STUDENTS][userId][DRAWINGS][backgroundId].map(
      (drawing) => (drawing.id === drawingId ? { ...drawing, deleted: true } : drawing),
    );
    return {
      ...state,
      [STUDENTS]: {
        ...state[STUDENTS],
        [userId]: {
          ...state[STUDENTS][userId],
          [DRAWINGS]: { ...state[STUDENTS][userId][DRAWINGS], [backgroundId]: drawings },
        },
      },
    };
  },
  [TYPES[RESET_DRAWINGS]]: (state, { payload: backgroundId }) => ({
    ...state,
    [STUDENTS]: Object.keys(state[STUDENTS]).reduce((students, key) => ({
      ...students,
      [key]: {
        ...state[STUDENTS][key],
        [DRAWINGS]: {
          ...state[STUDENTS][key][DRAWINGS],
          [backgroundId]: [],
        },
      },
    }), {}),
  }),
  [TYPES[FLAG_DRAWING]]: (state, { payload: drawing }) => {
    let newState = state;
    const { id, studentId, backgroundId } = drawing;
    const studentDrawings = newState[STUDENTS][studentId][DRAWINGS][backgroundId];

    newState = {
      ...newState,
      [STUDENTS]: {
        ...newState[STUDENTS],
        [studentId]: {
          ...newState[STUDENTS][studentId],
          [DRAWINGS]: {
            ...newState[STUDENTS][studentId][DRAWINGS],
            [backgroundId]: studentDrawings.map(
              (d) => (d.id === id ? drawing : d),
            ),
          },
        },
      },
    };

    return newState;
  },
  [TYPES[SHARE_DRAWING]]: (state, { payload: drawing }) => {
    let newState = state;

    const { id, studentId, backgroundId } = drawing;
    const studentDrawings = newState[STUDENTS][studentId][DRAWINGS][backgroundId];

    newState = {
      ...newState,
      [STUDENTS]: {
        ...newState[STUDENTS],
        [studentId]: {
          ...newState[STUDENTS][studentId],
          [DRAWINGS]: {
            ...newState[STUDENTS][studentId][DRAWINGS],
            [backgroundId]: studentDrawings.map(
              (d) => (d.id === id ? drawing : d),
            ),
          },
        },
      },
    };

    return newState;
  },
};

export default createReducer(behaviors, defaultState);
