import store from 'store';
import Axios from 'axios';
import logger from 'js-logger';
import { getQueue } from './redux/selectors';
import { fetchUser, setQueue, setError } from './redux/actions';
import { ERRORS } from './constants';

import tokenHandler from '../utils/tokenHandler';

Axios.interceptors.request.use((config) => {
  const token = tokenHandler.getToken();
  config.headers.Authorization = token;

  return config;
});

const { API_ROOT_PATH, NETWORK: { MAX_TRIES, INTERVAL } } = window.CONFIG;

const queryHandler = async (resolve, reject, fn, count) => {
  try {
    const result = await fn();
    resolve(result.data);
  } catch (e) {
    logger.error(e);
    switch (e?.response?.status) {
      case ERRORS.NO_SERVER:
        if (count < MAX_TRIES) {
          setTimeout(() => {
            queryHandler(resolve, reject, fn, count + 1);
          }, INTERVAL);
        } else {
          reject(ERRORS.NO_SERVER);
        }
        break;
      default:
        reject(e?.response?.status || ERRORS.UNKNOWN);
    }
  }
};

const queryWrapper = (fn) => new Promise((resolve, reject) => {
  queryHandler(resolve, reject, fn, 0);
});

const query = async (event, data, method = 'post') => {
  logger.info('Ajax query with', method, event, data);
  try {
    const result = await queryWrapper(() => Axios[method](API_ROOT_PATH + event, data));
    return result;
  } catch (e) {
    setError(e);
    throw e;
  }
};

class AjaxHandler {
  constructor() {
    this.sending = false;
  }

  async connect() {
    await fetchUser(this);
    this._send();
  }

  getKey() {
    return `i${Math.round(Math.random * 10000)}_${new Date().getTime()}`;
  }

  getQueue() {
    return getQueue(store.getState());
  }

  async _send() {
    if (!this.sending && this.getQueue().length) {
      const queue = this.getQueue();
      this.sending = true;
      const { event, data } = queue[queue.length - 1];
      store.dispatch(setQueue(queue.slice(0, queue.length - 1)));
      try {
        await query(event, data);
      } catch (e) {
        logger.error('Query send error', e);
      }
      this.sending = false;
      this._send();
    }
  }

  send(event, data) {
    store.dispatch(setQueue([{ event, data }, ...this.getQueue()]));
    // No need to await here
    this._send();
  }

  async fetch(event, data, callback) {
    this.sending = true;
    try {
      const result = await query(event, data);
      if (callback) {
        callback(result);
      }
    } catch (e) {
      logger.error('Fetch error', e);
    }
    this.sending = false;
  }

  async file(event, callback) {
    this.sending = true;
    try {
      const result = await query(event, null, 'get');
      if (callback) {
        callback(result);
      }
    } catch (e) {
      logger.error('Fetch error', e);
    }
    this.sending = false;
  }
}

export default new AjaxHandler();
