const STORAGE_KEY = 'ikonikat_token';

class TokenHandler {
  getToken() {
    return sessionStorage.getItem(STORAGE_KEY);
  }

  setToken(token) {
    return sessionStorage.setItem(STORAGE_KEY, token);
  }
}

export default new TokenHandler();
