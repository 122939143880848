import React, {
  useRef, useEffect,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';

const BackgroundImg = ({ src, onSizeChange, style }) => {
  const size = useRef({});

  const { width, height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  useEffect(() => {
    if (width && height && (size.current.width !== width || size.current.height !== height)) {
      size.current = { width, height };
      onSizeChange({ width, height });
    }
  }, [onSizeChange, height, width]);

  return <img ref={ref} src={src} style={style} alt="" />;
};

export default BackgroundImg;
