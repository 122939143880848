import React, { Suspense } from 'react';
import Loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';

import store, { persistor } from 'store';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import A2HSProvider from 'a2hs';
import { PersistGate } from 'redux-persist/integration/react';
// import FullScreen from 'components/FullScreen';
import { history } from 'routes';
import 'i18n';
import LocaleWatch from 'i18n/LocaleWatch';

import 'assets/fonts/ikonikat.css';
import './theme.scss';
import './app.scss';
import logger from 'js-logger';

logger.useDefaults({ defaultLevel: logger.INFO });

const Loading = () => <LoadingComponent />;
export const RootAsync = Loadable({
  loader: () => import('components/Layout'),
  loading: () => <Loading open />,
});

const App = () => (
  <Suspense fallback={<Loading open />}>
    <A2HSProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Switch>
              <Route>
                <LocaleWatch />
                {/* <FullScreen /> */}
                <RootAsync />
              </Route>
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </A2HSProvider>
  </Suspense>
);

export default App;
