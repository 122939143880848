import { createTypes } from 'store/utils';

export const KEY = 'restitution';

// Settings values

export const SESSIONS_LIST = 'sessionsList';
export const SET_SESSIONS_LIST = 'SET_SESSIONS_LIST';

export const IMAGES_LIST = 'imagesList';
export const SET_IMAGES_LIST = 'SET_IMAGES_LIST';

// Selected settings

export const SESSIONS = 'sessions';
export const SET_SESSIONS = 'SET_SESSIONS';

export const IMAGE = 'image';
export const SET_IMAGE = 'SET_IMAGE';

export const STUDENTS = 'students';
export const SET_STUDENTS = 'SET_STUDENTS';

export const DRAWINGS_NUMBER = 'drawingsNumber';
export const SET_DRAWINGS_NUMBER = 'SET_DRAWINGS_NUMBER';

export const RENDERING = 'rendering';
export const SET_RENDERING = 'SET_RENDERING';

// Heatmap settings

export const HEATMAP_SETTINGS = 'heatmapSettings';
export const SET_HEATMAP_SETTINGS = 'SET_HEATMAP_SETTINGS';

// Data

export const RESTITUTION_DATA = 'restitutionData';
export const SET_RESTITUTION_DATA = 'SET_RESTITUTION_DATA';

export const MyTypesArray = [
  SET_SESSIONS_LIST,
  SET_IMAGES_LIST,
  SET_SESSIONS,
  SET_IMAGE,
  SET_STUDENTS,
  SET_RESTITUTION_DATA,
  SET_DRAWINGS_NUMBER,
  SET_RENDERING,
  SET_HEATMAP_SETTINGS,
];
export const TYPES = createTypes(KEY, MyTypesArray);
