import React from 'react';
import Overlay from 'components/Overlay';
import PropTypes from 'prop-types';

const Logo = ({ open }) => (
  <Overlay open={open} className="logo">
    <i className="icon-logo" />
  </Overlay>
);

Logo.propTypes = {
  open: PropTypes.bool,
};

Logo.defaultProps = {
  open: false,
};

export default Logo;
