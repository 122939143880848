import React, { useMemo } from 'react';
import Overlay from 'components/Overlay';
import { Button } from 'components/Menu';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HelpItem = ({ icon, desc, className }) => {
  const icons = useMemo(() => (Array.isArray(icon) ? icon : [icon]), [icon]);
  const classes = useMemo(() => classNames('help-item', className), [className]);

  return (
    <div key={icon} className={classes}>
      {icons.map((icon) => <Button icon={icon} className="help-item__icon" />)}
      <div className="help-item__desc">{desc}</div>
    </div>
  );
};

const Help = ({ items, open, onClose }) => (
  <Overlay className="help" open={open}>
    <div className="close" onClick={onClose}>
      <i className="la la-times" />
    </div>
    <div className="help__items">
      {items.map((item) => (
        <HelpItem {...item} />
      ))}
    </div>
  </Overlay>
);

Help.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Help;
