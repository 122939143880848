import { createTypes } from 'store/utils';

export const KEY = 'prof';

export const STUDENTS = 'students';
export const SET_STUDENTS = 'SET_STUDENTS';

export const STUDENTS_LANG = 'studentsLang';
export const SET_STUDENTS_LANG = 'SET_STUDENTS_LANG';

export const GROUP_INFO = 'groupInfo';
export const SET_GROUP_INFO = 'SET_GROUP_INFO';

export const SHOW_BACKGROUND = 'showBackground';
export const SET_SHOW_BACKGROUND = 'SET_SHOW_BACKGROUND';

export const SHARING_ACTIVE = 'sharingActive';
export const SET_SHARING_ACTIVE = 'SET_SHARING_ACTIVE';

export const DRAWINGS = 'drawings';
export const ADD_DRAWING = 'ADD_DRAWING';
export const REMOVE_DRAWING = 'REMOVE_DRAWING';
export const FLAG_DRAWING = 'FLAG_DRAWING';
export const SHARE_DRAWING = 'SHARE_DRAWING';
export const RESET_DRAWINGS = 'RESET_DRAWINGS';

export const MyTypesArray = [
  SET_STUDENTS,
  ADD_DRAWING,
  REMOVE_DRAWING,
  SET_GROUP_INFO,
  SET_STUDENTS_LANG,
  FLAG_DRAWING,
  SHARE_DRAWING,
  RESET_DRAWINGS,
  SET_SHOW_BACKGROUND,
  SET_SHARING_ACTIVE,
];
export const TYPES = createTypes(KEY, MyTypesArray);
