import {
	TYPES,
	SET_BACKGROUNDS,
	BACKGROUNDS,
	SET_GROUPS_LIST,
	GROUPS_LIST,
	SET_GROUP,
	GROUP,
	SET_BACKGROUND,
	BACKGROUND,
	SET_DATA,
	DATA,
} from './constants';
import { createReducer, assignPayloadToKey } from 'store/utils';

const defaultState = {};

const behaviors = {
	[TYPES[SET_BACKGROUNDS]]: assignPayloadToKey(BACKGROUNDS),
	[TYPES[SET_BACKGROUND]]: assignPayloadToKey(BACKGROUND),
	[TYPES[SET_GROUPS_LIST]]: assignPayloadToKey(GROUPS_LIST),
	[TYPES[SET_GROUP]]: assignPayloadToKey(GROUP),
	[TYPES[SET_DATA]]: assignPayloadToKey(DATA),
};

export default createReducer(behaviors, defaultState);
