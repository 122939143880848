import { modes } from 'components/Heatmap';
import logger from 'js-logger';

export default class HeatmapHandler {
  constructor(canvas) {
    this._canvas = canvas = typeof canvas === 'string' ? document.getElementById(canvas) : canvas;

    this._ctx = canvas.getContext('2d');
    this._width = canvas.width;
    this._height = canvas.height;

    this._max = 1;
    this._data = [];
    this.defaultGradient = {
      0.4: 'blue',
      0.6: 'cyan',
      0.7: 'lime',
      0.8: 'yellow',
      1.0: 'red',
    };

    this.drawDots = this.drawDots.bind(this);
    this.drawLines = this.drawLines.bind(this);
    this.drawShapes = this.drawShapes.bind(this);

    this.drawers = {
      [modes.DOTS]: this.drawDots,
      [modes.LINES]: this.drawLines,
      [modes.SHAPES]: this.drawShapes,
    };
  }

  asyncProcess(array, fn, maxTimePerChunk = 100) {
    const test = Math.random();
    this.test = test;
    return new Promise((resolve, reject) => {
      let index = 0;
      const now = () => new Date().getTime();

      const doChunk = () => {
        const startTime = now();
        while (index < array.length && now() - startTime <= maxTimePerChunk) {
          fn(array[index], index);
          ++index;
        }
        if (test !== this.test) {
          reject();
        } else if (index < array.length) {
          setTimeout(doChunk, 1);
        } else if (index === array.length) {
          resolve();
        }
      };
      doChunk();
    });
  }

  data(data) {
    this._data = data;
    return this;
  }

  max(max) {
    this._max = max;
    return this;
  }

  add(point) {
    this._data.push(point);
    return this;
  }

  clear() {
    this._data = [];
    return this;
  }

  getShape(dots, blur) {
    const canvas = this._createCanvas();
    canvas.width = this._width * 2;
    canvas.height = this._height * 2;
    const ctx = canvas.getContext('2d');
    ctx.shadowOffsetX = this._width;
    ctx.shadowOffsetY = this._height;

    const [{ x, y }] = dots;
    ctx.shadowBlur = blur;
    ctx.shadowColor = 'black';

    ctx.beginPath();
    ctx.moveTo(x, y);

    for (let i = 1; i < dots.length; i++) {
      const { x, y } = dots[i];
      ctx.lineTo(x, y);
    }
    ctx.closePath();
    ctx.fill();

    return canvas;
  }

  drawShapes(context, blur) {
    return this.asyncProcess(this._data, ({ dots, type }) => {
      if (type === 'shape') {
        context.drawImage(this.getShape(dots, blur), -this._width, -this._height);
      }
    });
  }

  getLine(dots, lineWidth, blur) {
    const canvas = this._createCanvas();
    canvas.width = this._width * 2;
    canvas.height = this._height * 2;
    const ctx = canvas.getContext('2d');
    ctx.shadowOffsetX = this._width;
    ctx.shadowOffsetY = this._height;
    ctx.lineWidth = lineWidth;

    const [{ x, y }] = dots;
    ctx.shadowBlur = blur;
    ctx.shadowColor = 'black';

    ctx.beginPath();
    ctx.moveTo(x, y);

    for (let i = 1; i < dots.length; i++) {
      const { x, y } = dots[i];
      ctx.lineTo(x, y);
    }
    // ctx.closePath();
    ctx.stroke();

    return canvas;
  }

  drawLines(context, blur, lineWidth) {
    return this.asyncProcess(this._data, ({ dots }) => {
      context.drawImage(this.getLine(dots, lineWidth, blur), -this._width, -this._height);
    });
  }

  getCircle(r, blur) {
    // create a grayscale blurred circle image that we'll use for drawing points
    const circle = this._createCanvas();
    const ctx = circle.getContext('2d');
    const r2 = r + blur;

    circle.width = circle.height = r2 * 2;

    ctx.shadowOffsetX = ctx.shadowOffsetY = r2 * 2;
    ctx.shadowBlur = blur;
    ctx.shadowColor = 'black';

    ctx.beginPath();
    ctx.arc(-r2, -r2, r, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();

    return circle;
  }

  drawDots(context, blur, lineWidth) {
    const circle = this.getCircle(lineWidth, blur);
    const r = lineWidth + blur;

    return this.asyncProcess(this._data, ({ dots }) => {
      dots.forEach(({ x, y }) => {
        context.drawImage(circle, x - r, y - r);
      });
    });
  }

  resize() {
    this._width = this._canvas.width;
    this._height = this._canvas.height;
    return this;
  }

  gradient(grad) {
    // create a 256x1 gradient that we'll use to turn a grayscale heatmap into a colored one
    const canvas = this._createCanvas();
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 256);

    canvas.width = 1;
    canvas.height = 256;

    for (const i in grad) {
      gradient.addColorStop(+i, grad[i]);
    }

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, 1, 256);

    this._grad = ctx.getImageData(0, 0, 1, 256).data;

    return this;
  }

  async draw(mode, value, lineWidth, blur) {
    if (!this._grad) this.gradient(this.defaultGradient);

    const ctx = this._ctx;
    ctx.clearRect(0, 0, this._width, this._height);
    /*
    ctx.globalAlpha = Math.min(
      Math.max(value / 100 / this._max, minOpacity === undefined ? 0.05 : minOpacity),
      1,
    ); */

    const alpha = value / 100 / this._max;

    ctx.globalAlpha = alpha;

    const start = new Date().getTime();
    await this.drawers[mode](ctx, blur, lineWidth);

    // colorize the heatmap, using opacity value of each pixel
    // to get the right color from our gradient
    const colored = ctx.getImageData(0, 0, this._width, this._height);
    const range = this._colorize(colored.data, this._grad, alpha);
    ctx.putImageData(colored, 0, 0);
    logger.warn(`heatmap draws ${this._data.length} items in ${new Date().getTime() - start}ms`);
    return range;
  }

  _colorize(pixels, gradient, alpha) {
    let min = 1000000;
    let max = 0;
    for (let i = 0, len = pixels.length, j; i < len; i += 4) {
      j = pixels[i + 3] * 4; // get gradient color from opacity value

      if (j < min && j > 0) {
        min = j;
      }

      if (j > max) {
        max = j;
      }

      if (j) {
        // get rgb from gradient img which is a line of 255px filled with gradient colors
        pixels[i] = gradient[j];
        pixels[i + 1] = gradient[j + 1];
        pixels[i + 2] = gradient[j + 2];
      }
    }

    return {
      min,
      max,
      gradient,
      alpha,
    };
  }

  _createCanvas() {
    if (typeof document !== 'undefined') {
      return document.createElement('canvas');
    }
    // create a new canvas instance in node.js
    // the canvas class needs to have a default constructor without any parameter
    return new this._canvas.constructor();
  }
}
