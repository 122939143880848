import React from 'react';
import Drawing from 'components/Drawing';

import './heatmap.scss';

const selectedColor = {
  c1: 'ff0000',
  c2: 'ff0000',
  id: 'carre_violet',
  n: 'rose',
};

const HeatmapSelectedDrawing = ({ drawing, size }) => (
  <Drawing
    drawing={drawing}
    width={size.width}
    height={size.height}
    color={selectedColor}
    className="heatmap-selected-drawing"
  />
);

export default HeatmapSelectedDrawing;
