import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES, SET_TOKEN, TOKEN, SET_ERROR, ERROR, SETTINGS, SET_SETTINGS,
} from './constants';

const defaultState = { [TOKEN]: '' };

const behaviors = {
  [TYPES[SET_TOKEN]]: assignPayloadToKey(TOKEN),
  [TYPES[SET_ERROR]]: assignPayloadToKey(ERROR),
  [TYPES[SET_SETTINGS]]: assignPayloadToKey(SETTINGS),
};

export default createReducer(behaviors, defaultState);
