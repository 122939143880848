export const ERRORS = {
  NOT_FOUND: 404,
  GROUP_NOT_FOUND: 410,
  INVALID_TOKEN: 498,
  UNKNOWN: 500,
  NO_SERVER: 502,
  UNKNOWN_SERVER: 520,
};

export default null;
